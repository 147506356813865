import { Box } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import CustomHashLink from '../Common/CustomHashLink'
import styles from './header.module.scss'
import { MENU_ITEMS } from './MENU_ITEMS'

const MenuBar: React.FC = () => {
  const { pathname } = useLocation()

  if (pathname === '/Maintenance') {
    return null
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-around">
      <Box display="flex" alignItems="center">
        <CustomHashLink id="top" name="ホーム" className={styles.menu_item__home}>
          ホーム
        </CustomHashLink>
        {MENU_ITEMS.map((item) => (
          <div key={item.id}>
            <CustomHashLink id={item.id} name={item.name} className={styles.menu_item}>
              {item.name}
            </CustomHashLink>
          </div>
        ))}
      </Box>

      <Box className={styles.menu_item__gradient}>
        <HashLink smooth to="/#contact" className={styles.pc_contact_btn}>
          お問合せ
        </HashLink>
      </Box>
    </Box>
  )
}
export default MenuBar
