import com600 from '../../../assets/png/companies/logo_600.png' // 600
import ActiveAndCompany from '../../../assets/png/companies/logo_activeandcompany.png' // 株式会社アクティブアンドカンパニー
import Akikatu from '../../../assets/png/companies/logo_akikatsu.png' //　空キ家活用株式会社
import Ancar from '../../../assets/png/companies/logo_ancar.png' // 株式会社Ancar
import MansionMarket from '../../../assets/png/companies/logo_apartmentmarket.png' // 株式会社マンションマーケット
import Asobiba from '../../../assets/png/companies/logo_asobica.png' // 株式会社Asobiba
import Babel from '../../../assets/png/companies/logo_babel.png' // 株式会社バベル
import BraveGroup from '../../../assets/png/companies/logo_brave_group.jpg' // 株式会社BraveGroup
import BrightTable from '../../../assets/png/companies/logo_brighttableinc.png' // 株式会社ブライトテーブル
import Goopass from '../../../assets/png/companies/logo_goopass.jpg' // カメラブ株式会社
import Crevo from '../../../assets/png/companies/logo_crevo.png' // Crevo株式会社
import Dandori from '../../../assets/png/companies/logo_dandoliworks.png' // 株式会社ダンドリワーク
import DELIPICKS from '../../../assets/png/companies/logo_delipicks.jpg' // 株式会社DELIPICKS
import EmotionTech from '../../../assets/png/companies/logo_emotiontech.png' // 株式会社EmotionTech
import Famione from '../../../assets/png/companies/logo_famione.png' // 株式会社ファミワン
import Focus from '../../../assets/png/companies/logo_focus.png' // FOCUS株式会社
import Funds from '../../../assets/png/companies/logo_funds.png' // ファンズ株式会社
import FoodCode from '../../../assets/png/companies/logo_foodcode.jpg' // FOODCODE株式会社
import FutureStandard from '../../../assets/png/companies/logo_futurestandard.png'
import HrBrain from '../../../assets/png/companies/logo_hrbrain.png' // 株式会社HRBrain
import Jiraffe from '../../../assets/png/companies/logo_jiraffe.png' // 株式会社Jiraffe
import JX from '../../../assets/png/companies/logo_jx.png' // 株式会社JX通信社
import Kipp from '../../../assets/png/companies/logo_kippfinancialtechnologies.png' // Kipp Financial Technologies株式会社
import Kyash from '../../../assets/png/companies/logo_kyash.png' // 株式会社Kyash
import Lafool from '../../../assets/png/companies/logo_lafool.png' //　 株式会社ラフール
import LoanBoy from '../../../assets/png/companies/logo_leanbody.png' // 株式会社LEAN BODY
import loveGraph from '../../../assets/png/companies/logo_lovegraph.png' // 株式会社ラブグラフ
import Mikatus from '../../../assets/png/companies/logo_mikatus.png' // Mikatus株式会社
import Minacolor from '../../../assets/png/companies/logo_minacolor.png' // 株式会社ミナカラ
import Noin from '../../../assets/png/companies/logo_noin.png' // ノイン株式会社
import NonBrokers from '../../../assets/png/companies/logo_nonbrokers.png' // NonBrokers株式会社
import Novera from '../../../assets/png/companies/logo_novera.png' // 株式会社Novera
import OhMyGlass from '../../../assets/png/companies/logo_ohmyglasses.png' // オーマイグラス株式会社
import PanForYou from '../../../assets/png/companies/logo_panforyou.png' // 株式会社パンフォーユー
import Patra from '../../../assets/png/companies/logo_patra.png' // 株式会社PATRA
import Paytner from '../../../assets/png/companies/logo_paytner.png' // ペイトナー株式会社
import Spika from '../../../assets/png/companies/logo_spika.png' //　株式会社スピカ
import Popshoot from '../../../assets/png/companies/logo_popshoot.png' // 株式会社PopShoot
import PuzzleRing from '../../../assets/png/companies/logo_puzzlering.png' // 株式会社パズルリング
import Refcome from '../../../assets/png/companies/logo_refcome.png' // 株式会社リフカム
import Regali from '../../../assets/png/companies/logo_regali.png' // 株式会社REGALI
import Reject from '../../../assets/png/companies/logo_reject.png' // 株式会社REJECT
import RFLoucus from '../../../assets/png/companies/logo_rflocus.png' // RFルーカス株式会社
import Siibo from '../../../assets/png/companies/logo_siiibo.png' // 株式会社Siibo
import SmartIdea from '../../../assets/png/companies/logo_smartidea.png' // スマートアイデア株式会社
import SmartShopping from '../../../assets/png/companies/logo_smartshopping.png' // 株式会社スマートショッピング
import SocialInterior from '../../../assets/png/companies/logo_socialinterior.jpg' // 株式会社ソーシャルインテリア
import Sukedatchi from '../../../assets/png/companies/logo_sukedachi.png' // 株式会社助太刀
import SuMaTen from '../../../assets/png/companies/logo_sumaten.jpg' // 株式会社スマテン
import TakeMe from '../../../assets/png/companies/logo_takeme.png' // TakeMe株式会社
import TORANOTEC from '../../../assets/png/companies/logo_toranotec.png' // TORANOTEC株式会社
import ToySub from '../../../assets/png/companies/logo_toysub.png' // 株式会社トラーナ
import Upsider from '../../../assets/png/companies/logo_upsider.png' // kabusikigaisya
import Yorisou from '../../../assets/png/companies/logo_yorisou.png' // 株式会社ヨリソウ
import StartUpTechnology from '../../../assets/png/companies/logo_startuptechnology.png' // スタートアップテクノロジー
import SmartBank from '../../../assets/png/companies/logo_b43.png' // 株式会社スマートバンク
import NetOn from '../../../assets/png/companies/logo_neton.png' // 株式会社ネットオン
import Biophilia from '../../../assets/png/companies/logo_biophilia.png' // 株式会社バイオフィリア
import Emerada from '../../../assets/png/companies/logo_emerada.png' // 株式会社エメラダ
import Crowdloan from '../../../assets/png/companies/logo_crowdloan.png' // クラウドローン株式会社
import Leadingmark from '../../../assets/png/companies/logo_leadingmark.png' // 株式会社リーディングマーク
import Sorabito from '../../../assets/png/companies/logo_sorabito.png' // SORABITO株式会社
import Radiotalk from '../../../assets/png/companies/logo_radiotalk.png' // Radiotalk株式会社
import GoTodayShaireSalon from '../../../assets/png/companies/logo_gotodayshairesalon.png' // 株式会社GoToShaireSalon
import Drobe from '../../../assets/png/companies/logo_drobe.png' // 株式会社DROBE
import SharingEnergy from '../../../assets/png/companies/logo_sharing_energy.jpg' // 株式会社シェアリングエネルギー
import Scheeme from '../../../assets/png/companies/logo_bluebank.jpg'
import Overfrow from '../../../assets/png/companies/logo_overflow.jpg'
import Bitstar from '../../../assets/png/companies/logo_bitstar.jpg'
import Lestas from '../../../assets/png/companies/logo_lestas.jpg'
import Renoveru from '../../../assets/png/companies/logo_renoveru.jpg'
import Dricos from '../../../assets/png/companies/logo_dricos.jpg'
import FastBeauty from '../../../assets/png/companies/logo_fastbeauty.jpg'
import CLUE from '../../../assets/png/companies/logo_clue.jpg'
import OLTA from '../../../assets/png/companies/logo_olta.jpg'
import Aalda from "../../../assets/png/companies/logo_A'aldaJapan.jpg"
import Valley from '../../../assets/png/companies/logo_valley.jpg'
import JutakuLoanShindanCompany from '../../../assets/png/companies/logo_MortgageDiagnosisCompany.png'
import Alt from '../../../assets/png/companies/logo_alt.jpg'
import TsujiHongo from '../../../assets/png/companies/logo_HongoTsujiITConsulting.png'
import TransferData from '../../../assets/png/companies/transfer_data.jpg'
import Lamp from '../../../assets/png/companies/logo_lamp.jpg'
import Petokoto from '../../../assets/png/companies/logo_petokoto.jpg'
import Movus from '../../../assets/png/companies/movus.jpg'
import Jpyc from '../../../assets/png/companies/jpyc.jpg'
import GoodVibesOnly from '../../../assets/png/companies/good_vibes_only.jpg'
import Kauche from '../../../assets/png/companies/KAUCHE.jpg'
import Roxx from '../../../assets/png/companies/ROXX.jpg'
import Spacer from '../../../assets/png/companies/SPACER.jpg'
import Chipee from '../../../assets/png/companies/chipee.jpg'

export type Company = {
  name: string
  logoUrl: string
  url: string
  category: 'NEW' | 'IPO' | 'MandA' | 'None'
}

export const companies: Company[] = [
  {
    name: 'SPACER',
    logoUrl: Spacer,
    url: 'https://company.spacer.co.jp/',
    category: 'NEW'
  },
  {
    name: 'chipee',
    logoUrl: Chipee,
    url: 'https://about.chipee.jp/',
    category: 'NEW'
  },
  {
    name: 'KAUCHE',
    logoUrl: Kauche,
    url: 'https://about.kauche.com/',
    category: 'None'
  },
  {
    name: 'ROXX',
    logoUrl: Roxx,
    url: 'https://roxx.co.jp/',
    category: 'IPO'
  },
  {
    name: 'movus technologies',
    logoUrl: Movus,
    url: 'https://mo-vus.com/',
    category: 'None'
  },
  {
    name: 'JPYC',
    logoUrl: Jpyc,
    url: 'https://jpyc.co.jp/',
    category: 'None'
  },
  {
    name: 'GOOD VIBES ONLY',
    logoUrl: GoodVibesOnly,
    url: 'https://goodvibesonly.jp/',
    category: 'None'
  },
  {
    name: 'スマテン',
    logoUrl: SuMaTen,
    url: 'https://corp.sumaten.co/',
    category: 'None'
  },
  {
    name: 'FOODCODE',
    logoUrl: FoodCode,
    url: 'https://foodcode.jp/',
    category: 'None'
  },
  {
    name: 'シェアリングエネルギー',
    logoUrl: SharingEnergy,
    url: 'https://sharing-energy.co.jp/',
    category: 'None'
  },
  {
    name: 'ソーシャルインテリア',
    logoUrl: SocialInterior,
    url: 'https://corp.socialinterior.com/',
    category: 'None'
  },
  {
    name: 'DELIPICKS',
    logoUrl: DELIPICKS,
    url: 'https://deli-picks.com/',
    category: 'None'
  },
  {
    name: 'PETOKOTO',
    logoUrl: Petokoto,
    url: 'https://corp.petokoto.com/',
    category: 'None'
  },
  {
    name: 'ランプ',
    logoUrl: Lamp,
    url: 'https://lamp.jp/',
    category: 'None'
  },
  {
    name: '住宅ローン診断カンパニー',
    logoUrl: JutakuLoanShindanCompany,
    url: 'https://loan-support-one.home-loan.jp/',
    category: 'None'
  },
  {
    name: 'オルツ',
    logoUrl: Alt,
    url: 'https://alt.ai/',
    category: 'IPO'
  },
  {
    name: "A'alda Pte. Ltd.",
    logoUrl: Aalda,
    category: 'None',
    url: 'https://aalda.com/ja/'
  },
  {
    name: 'ヴァレイ',
    logoUrl: Valley,
    category: 'None',
    url: 'https://www.valleymode.com/'
  },
  {
    name: 'CLUE',
    logoUrl: CLUE,
    category: 'None',
    url: 'https://corp.t-clue.com/'
  },
  {
    name: 'Fast Beauty',
    logoUrl: FastBeauty,
    category: 'None',
    url: 'https://fufucolor.com/'
  },
  {
    name: 'OLTA',
    logoUrl: OLTA,
    category: 'None',
    url: 'https://corp.olta.co.jp/pr/2023-0621'
  },
  {
    name: 'ドリコス',
    logoUrl: Dricos,
    category: 'None',
    url: 'https://dricos.jp/'
  },
  {
    name: 'リノべる',
    logoUrl: Renoveru,
    category: 'None',
    url: 'https://renoveru.co.jp/'
  },
  {
    name: 'Scheeme',
    logoUrl: Scheeme,
    category: 'None',
    url: 'https://bluebank.app/'
  },
  {
    name: 'BitStar',
    logoUrl: Bitstar,
    category: 'None',
    url: 'https://corp.bitstar.tokyo'
  },
  {
    name: 'レスタス',
    logoUrl: Lestas,
    category: 'None',
    url: 'https://lestas.jp/'
  },

  {
    name: 'DROBE',
    logoUrl: Drobe,
    category: 'None',
    url: 'https://drobe.co.jp/'
  },
  {
    name: 'GO TODAY SHAiRE SALON',
    logoUrl: GoTodayShaireSalon,
    category: 'None',
    url: 'https://www.shairesalon-go.today/'
  },
  {
    name: 'Radiotalk',
    logoUrl: Radiotalk,
    category: 'None',
    url: 'https://radiotalk.jp/'
  },
  {
    name: 'SORABITO',
    logoUrl: Sorabito,
    category: 'None',
    url: 'https://www.sorabito.com/'
  },
  {
    name: 'リーディングマーク',
    logoUrl: Leadingmark,
    category: 'None',
    url: 'https://www.leadingmark.jp/'
  },
  {
    name: 'クラウドローン',
    logoUrl: Crowdloan,
    category: 'None',
    url: 'https://crowdloan.jp/'
  },
  {
    name: 'エメラダ',
    logoUrl: Emerada,
    category: 'None',
    url: 'https://www.emerada.co.jp/'
  },
  {
    name: 'バイオフィリア',
    logoUrl: Biophilia,
    category: 'None',
    url: 'https://biophilia.co.jp/'
  },
  {
    name: 'ネットオン',
    logoUrl: NetOn,
    category: 'None',
    url: 'https://saiyo-kakaricho.com/'
  },
  {
    name: 'スマートバンク',
    logoUrl: SmartBank,
    category: 'None',
    url: 'https://smartbank.co.jp/'
  },
  {
    name: 'スタートアップテクノロジー',
    logoUrl: StartUpTechnology,
    category: 'None',
    url: 'https://startup-technology.com/'
  },
  {
    name: 'パズルリング',
    logoUrl: PuzzleRing,
    category: 'None',
    url: 'https://www.lastmessage.rip/'
  },
  {
    name: 'ペイトナー',
    logoUrl: Paytner,
    category: 'None',
    url: 'https://yup.jp/company'
  },
  {
    name: 'overflow',
    logoUrl: Overfrow,
    category: 'None',
    url: 'https://overflow.co.jp'
  },
  {
    name: 'アクティブアンドカンパニー',
    logoUrl: ActiveAndCompany,
    category: 'None',
    url: 'https://www.aand.co.jp/'
  },
  {
    name: 'トラーナ',
    logoUrl: ToySub,
    category: 'None',
    url: 'https://toysub.net/'
  },
  {
    name: 'TORANOTEC',
    logoUrl: TORANOTEC,
    category: 'None',
    url: 'https://toranotec.com/'
  },
  {
    name: 'パンフォーユー',
    logoUrl: PanForYou,
    category: 'None',
    url: 'https://panforyou.jp/'
  },
  {
    name: 'REJECT',
    logoUrl: Reject,
    category: 'None',
    url: 'https://reject.co.jp/'
  },
  {
    name: 'リフカム',
    logoUrl: Refcome,
    category: 'MandA',
    url: 'https://jp.refcome.com/'
  },
  {
    name: '辻・本郷ITコンサルティング',
    logoUrl: TsujiHongo,
    category: 'None',
    url: 'https://ht-itc.jp/'
  },
  {
    name: 'ラフール',
    logoUrl: Lafool,
    category: 'None',
    url: 'https://www.lafool.co.jp/'
  },
  {
    name: 'GOOPASS',
    logoUrl: Goopass,
    category: 'None',
    url: 'https://goopass.co.jp/'
  },
  {
    name: 'LEAN BODY',
    logoUrl: LoanBoy,
    category: 'None',
    url: 'https://lp.lean-body.jp/'
  },
  {
    name: 'スマートショッピング',
    logoUrl: SmartShopping,
    category: 'None',
    url: 'https://smartshopping.co.jp/'
  },
  {
    name: 'HRBrain',
    logoUrl: HrBrain,
    category: 'MandA',
    url: 'https://www.hrbrain.co.jp/'
  },
  {
    name: '助太刀',
    logoUrl: Sukedatchi,
    category: 'None',
    url: 'https://suke-dachi.jp/company/'
  },
  {
    name: 'Novera',
    logoUrl: Novera,
    category: 'None',
    url: 'https://corporate.novera.co.jp/'
  },
  {
    name: 'Siiibo証券',
    logoUrl: Siibo,
    category: 'None',
    url: 'https://siiibo.com/'
  },
  {
    name: 'REGALI',
    logoUrl: Regali,
    category: 'None',
    url: 'https://regali.co.jp/'
  },
  {
    name: 'FOCUS',
    logoUrl: Focus,
    category: 'None',
    url: 'https://www.focus-ad.jp/'
  },
  {
    name: 'ジラフ',
    logoUrl: Jiraffe,
    category: 'None',
    url: 'https://jiraffe.co.jp/'
  },
  {
    name: 'ファミワン',
    logoUrl: Famione,
    category: 'None',
    url: 'https://famione.co.jp/'
  },
  {
    name: '空き家活用',
    logoUrl: Akikatu,
    category: 'None',
    url: 'https://aki-katsu.co.jp/'
  },
  {
    name: 'TakeMe',
    logoUrl: TakeMe,
    category: 'None',
    url: 'https://www.takeme.com/'
  },
  {
    name: 'よりそう',
    logoUrl: Yorisou,
    category: 'None',
    url: 'https://www.yoriso.com/corp/'
  },
  {
    name: 'Non Brokers',
    logoUrl: NonBrokers,
    category: 'MandA',
    url: 'https://www.non-brokers.com/'
  },
  {
    name: 'Asobiba',
    logoUrl: Asobiba,
    category: 'None',
    url: 'https://asobica.co.jp/'
  },
  {
    name: 'Brave group',
    logoUrl: BraveGroup, //　ロゴ変更確認
    category: 'None',
    url: 'https://bravegroup.co.jp/'
  },
  {
    name: 'ノイン',
    logoUrl: Noin,
    category: 'None',
    url: 'https://noin.shop/'
  },
  {
    name: 'PATRA',
    logoUrl: Patra,
    category: 'MandA',
    url: 'https://patrainc.jp/'
  },
  {
    name: 'RFルーカス',
    logoUrl: RFLoucus,
    category: 'None',
    url: 'https://rflocus.com/'
  },
  {
    name: '600',
    logoUrl: com600,
    category: 'None',
    url: 'https://600.jp/'
  },
  {
    name: 'ミナカラ',
    logoUrl: Minacolor,
    category: 'MandA',
    url: 'https://minacolor.com/pages/corporate'
  },
  {
    name: 'Kipp Financial Technologies',
    logoUrl: Kipp,
    category: 'None',
    url: 'https://kipp-corp.com/'
  },
  {
    name: 'BABEL',
    logoUrl: Babel,
    category: 'None',
    url: 'https://www.babel.jp/'
  },
  {
    name: 'ラブグラフ',
    logoUrl: loveGraph,
    category: 'MandA',
    url: 'https://corporate.lovegraph.me/'
  },
  {
    name: 'ダンドリワーク',
    logoUrl: Dandori,
    category: 'None',
    url: 'https://dandori-work.co.jp/'
  },
  {
    name: 'UPSIDER',
    logoUrl: Upsider,
    category: 'None',
    url: 'https://up-sider.com/lp/'
  },
  {
    name: 'トランスファーデータ',
    logoUrl: TransferData,
    category: 'None',
    url: 'https://transferdata.co.jp/'
  },
  {
    name: 'フューチャースタンダード',
    logoUrl: FutureStandard,
    category: 'None',
    url: 'https://www.futurestandard.co.jp/'
  },
  {
    name: 'Ancar',
    logoUrl: Ancar,
    category: 'None',
    url: 'https://ancar.co.jp/'
  },
  {
    name: 'スマートアイデア',
    logoUrl: SmartIdea,
    category: 'None',
    url: 'http://smart-idea.jp/'
  },
  {
    name: 'Emotion Tech',
    logoUrl: EmotionTech,
    category: 'MandA',
    url: 'https://www.emotion-tech.co.jp/'
  },
  {
    name: 'オーマイグラス',
    logoUrl: OhMyGlass,
    category: 'None',
    url: 'https://www.ohmyglasses.co.jp/'
  },
  {
    name: 'ファンズ',
    logoUrl: Funds,
    category: 'None',
    url: 'https://corp.funds.jp/'
  },
  {
    name: 'JX通信社',
    logoUrl: JX,
    category: 'None',
    url: 'https://jxpress.net/'
  },
  {
    name: 'スピカ',
    logoUrl: Spika,
    category: 'None',
    url: 'https://www.spika.co.jp/' //　　ロゴが変わっている
  },
  {
    name: 'Crevo',
    logoUrl: Crevo,
    category: 'None',
    url: 'https://crevo.jp/'
  },
  {
    name: 'Popshoot',
    logoUrl: Popshoot,
    category: 'None',
    url: 'http://popshoot.co.jp/'
  },
  {
    name: 'マンションマーケット',
    logoUrl: MansionMarket,
    category: 'None',
    url: 'https://corp.mansion-market.com/'
  },
  {
    name: 'ブライトテーブル',
    logoUrl: BrightTable,
    category: 'None',
    url: 'https://bright-table.com/'
  },
  {
    name: 'Kyash',
    logoUrl: Kyash,
    category: 'None',
    url: 'https://www.kyash.co/'
  },
  {
    name: 'Mikatus',
    logoUrl: Mikatus,
    category: 'MandA',
    url: 'https://mikatus.com/'
  }
]
